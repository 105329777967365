import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  CalendarTodayOutlined,
  RoomOutlined,
  ScheduleOutlined,
} from '@material-ui/icons'
import { rgba } from 'polished'
import React, { FC, MouseEvent } from 'react'

import { LifeGroup } from '@/models/LifeGroup'

type StyleProps = {
  isOpenGroup: LifeGroup['is_open']
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 340,
      boxShadow: 'none',
      borderRadius: 0,
      [breakpoints.down('md')]: {
        width: 300,
      },
      [breakpoints.down('sm')]: {
        width: 270,
      },
      [breakpoints.down('xs')]: {
        flexDirection: 'row',
        width: '100%',
      },
    },
    image: {
      position: 'relative',
      padding: spacing(11, 3, 2),
      height: 160,
      [breakpoints.down('md')]: {
        height: 120,
        padding: spacing(6, 3, 2),
      },
      [breakpoints.down('sm')]: {
        height: 100,
        padding: spacing(4, 2, 2),
      },
      [breakpoints.down('xs')]: {
        width: '40%',
        height: '100%',
        padding: spacing(2),
      },
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        display: 'block',
        content: '""',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    title: {
      position: 'relative',
      zIndex: 1,
      fontSize: '1.25rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.009em',
      color: palette.primary.contrastText,
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      position: 'relative',
      flex: 1,
      backgroundColor: 'rgba(233, 233, 233, 0.5)',
      '&:last-child': {
        paddingBottom: 'initial',
      },
    },
    chip: {
      position: 'absolute',
      top: spacing(2),
      right: spacing(2),
      borderRadius: 6,
      height: 27,
      fontSize: '1rem',
      backgroundColor: ({ isOpenGroup }) =>
        isOpenGroup ? rgba('#7DAA49', 0.25) : rgba('#E16574', 0.25),
      color: ({ isOpenGroup }) => (isOpenGroup ? '#7DAA49' : '#E16574'),
      [breakpoints.down('sm')]: {
        height: 24,
        fontSize: '0.875rem',
      },
      [breakpoints.down('sm')]: {
        height: 20,
        fontSize: '0.75rem',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(3),
      fontSize: '1.25rem',
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('sm')]: {
        marginBottom: spacing(1.5),
        fontSize: '1rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
    },
    icon: {
      marginRight: spacing(2),
      fontSize: '1.2em',
      color: palette.primary.main,
      [breakpoints.down('sm')]: {
        marginRight: spacing(1),
      },
    },
    tags: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: spacing(4),
      [breakpoints.down('xs')]: {
        marginBottom: spacing(2),
      },
      '& > *:not(:last-child)': {
        marginRight: spacing(1.5),
        [breakpoints.down('xs')]: {
          marginRight: spacing(1),
        },
      },
    },
    tag: {
      marginBottom: spacing(1),
    },
    button: {
      alignSelf: 'center',
      marginBottom: spacing(3),
      [breakpoints.down('xs')]: {
        alignSelf: 'flex-end',
        marginBottom: spacing(2),
      },
    },
  })
)

type LifeGroupsCardProps = {
  lifeGroup: LifeGroup
  onClickOpen: (event: MouseEvent) => void
}

export const LifeGroupsCard: FC<LifeGroupsCardProps> = ({
  lifeGroup,
  onClickOpen,
}) => {
  const classes = useStyles({ isOpenGroup: lifeGroup.is_open })

  return (
    <Card className={classes.root}>
      <CardMedia
        image={lifeGroup.photo_url}
        title={lifeGroup.name}
        className={classes.image}
      >
        <div className={classes.title}>{lifeGroup.name}</div>
      </CardMedia>
      <CardContent className={classes.content}>
        <Chip
          className={classes.chip}
          size="small"
          label={lifeGroup.is_open ? 'Open' : 'Full'}
        />
        <div>
          <div className={classes.row}>
            <CalendarTodayOutlined className={classes.icon} />
            <span>{lifeGroup.days}</span>
          </div>
          <div className={classes.row}>
            <ScheduleOutlined className={classes.icon} />
            <span>{lifeGroup.time}</span>
          </div>
          <div className={classes.row}>
            <RoomOutlined className={classes.icon} />
            <span>{lifeGroup.location}</span>
          </div>
          <div className={classes.tags}>
            {lifeGroup.tags.map((tag) => (
              <Chip
                key={`${tag.type}=${tag.value}`}
                size="small"
                variant="outlined"
                label={tag.value}
                className={classes.tag}
              />
            ))}
          </div>
        </div>
        <Button className={classes.button} onClick={onClickOpen}>
          Contact Host
        </Button>
      </CardContent>
    </Card>
  )
}
