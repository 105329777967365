import { makeStyles } from '@material-ui/core'
import React, { FC, useContext, useState } from 'react'

import { SectionTitle } from '@/components/common/SectionTitle'

import { LifeGroupFormDialog } from './LifeGroupFormDialog'
import { LifeGroupsCard } from './LifeGroupsCard'
import { LifeGroupsContext } from './LifeGroupsContext'
import { LifeGroupsFilters } from './LifeGroupsFilters'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  title: {
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '1.25rem',
    letterSpacing: '0.03125em',
    lineHeight: 1.6,
    [breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  group: {
    '&:not(:last-child)': {
      marginBottom: spacing(16),
    },
    [breakpoints.down('xs')]: {
      '&:not(:last-child)': {
        marginBottom: spacing(4),
      },
    },
  },
  groupTitle: {
    fontSize: '1.5rem',
    fontWeight: typography.fontWeightBold,
    letterSpacing: '0.041666',
    textAlign: 'left',
    marginBottom: spacing(4),
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    margin: spacing(-5, -2.5),
    [breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      margin: spacing(-3, -1.5),
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 0,
    },
    '& > div': {
      margin: spacing(5, 2.5),
      [breakpoints.down('sm')]: {
        margin: spacing(3, 1.5),
      },
      [breakpoints.down('xs')]: {
        margin: spacing(0, 0, 2),
      },
    },
  },
  emptyText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: '100%',
    fontSize: '1.25rem',
    [breakpoints.down('sm')]: {
      height: 100,
      fontSize: '1rem',
    },
  },
}))

export const LifeGroupsList: FC = () => {
  const classes = useStyles()
  const { displayedLifeGroups } = useContext(LifeGroupsContext)
  const [openFormKey, setOpenFormKey] = useState<number | null>(null)

  return (
    <div>
      <SectionTitle className={classes.title}>Join a Life Group</SectionTitle>
      <p className={classes.subtitle}>
        Being a part of a Life Group is a great way to connect with others,
        build friendships and grow in faith! Each Life Group is made of a group
        of people, either meeting online and/or in-person a few times a month to
        do life together as they seek to deepen their relationships with God and
        others.
      </p>
      <LifeGroupsFilters />
      <div className={classes.cards}>
        {displayedLifeGroups.length > 0 ? (
          displayedLifeGroups.map((lifeGroup, index) => (
            <LifeGroupsCard
              key={lifeGroup.name}
              lifeGroup={lifeGroup}
              onClickOpen={() => setOpenFormKey(index)}
            />
          ))
        ) : (
          <div className={classes.emptyText}>
            No matching groups for your search.
          </div>
        )}
      </div>
      <LifeGroupFormDialog
        index={openFormKey}
        lifeGroup={displayedLifeGroups.find(
          (_, index) => index === openFormKey
        )}
        onClose={() => setOpenFormKey(null)}
      />
    </div>
  )
}
