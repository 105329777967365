import { useState } from 'react'

import { LifeGroup, LifeGroupTag } from '@/models/LifeGroup'

export type LifeGroupFilters = Record<LifeGroupTag['type'], string>
export type LifeGroupFilterOptions = Record<LifeGroupTag['type'], string[]>

export function useLifeGroupsState(
  lifeGroups: LifeGroup[],
  lifeGroupTags: LifeGroupTag[]
) {
  const [filters, setFilters] = useState<LifeGroupFilters>({
    lifeStage: 'All',
    status: 'All',
    gender: 'All',
    language: 'All',
  })

  const filterOptions = lifeGroupTags.reduce(
    (result, tag) => {
      result[tag.type].push(tag.value)
      return result
    },
    {
      lifeStage: ['All'],
      status: ['All'],
      gender: ['All'],
      language: ['All'],
    } as LifeGroupFilterOptions
  )

  const displayedLifeGroups = lifeGroups
    .map((lg) => ({ ...lg, tags: lg.tags.slice().sort(compareTags) }))
    .sort(compareByDays)
    .filter((lg) => isTagged(lg, 'lifeStage', filters.lifeStage))
    .filter((lg) => isTagged(lg, 'status', filters.status))
    .filter((lg) => isTagged(lg, 'gender', filters.gender))
    .filter((lg) => isTagged(lg, 'language', filters.language))

  return {
    displayedLifeGroups,
    filters,
    filterOptions,
    setFilters,
  }
}

function isTagged(
  lifeGroup: LifeGroup,
  tagType: LifeGroupTag['type'],
  filterValue: string
): boolean {
  return (
    filterValue === 'All' ||
    lifeGroup.tags.some(
      (tag) => tag.type === tagType && tag.value === filterValue
    )
  )
}

function compareTags(firstTag: LifeGroupTag, secondTag: LifeGroupTag) {
  const firstIndex = orderedTagTypes.findIndex(
    (tagType) => tagType === firstTag.type
  )
  const secondIndex = orderedTagTypes.findIndex(
    (tagType) => tagType === secondTag.type
  )

  if (firstIndex < secondIndex) return -1
  if (firstIndex > secondIndex) return 1
  return 0
}

export const orderedTagTypes: LifeGroupTag['type'][] = [
  'lifeStage',
  'status',
  'gender',
  'language',
]

const daysToNumberMap: Record<string, number> = {
  Sundays: 0,
  Mondays: 1,
  Tuesdays: 2,
  Wednesdays: 3,
  Thursdays: 4,
  Fridays: 5,
  Saturdays: 6,
}

function compareByDays(lg1: LifeGroup, lg2: LifeGroup): number {
  const a = daysToNumberMap[lg1.days]
  const b = daysToNumberMap[lg2.days]

  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}
