import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { PageCover } from '@/components/common/PageCover'
import { GetStartedSection } from '@/components/next-steps/GetStartedSection'
import { LifeGroupsContext } from '@/components/next-steps/LifeGroupsContext'
import { LifeGroupsList } from '@/components/next-steps/LifeGroupsList'
import { QuoteSection } from '@/components/next-steps/QuoteSection'
import { useLifeGroupsState } from '@/components/next-steps/useLifeGroupsState'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "life-groups" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    lifeGroupTags: allStrapiLifeGroupTags {
      nodes {
        type
        value
      }
    }
    lifeGroups: allStrapiLifeGroups(sort: { fields: [id], order: ASC }) {
      nodes {
        name
        days
        time
        location
        is_open
        tags {
          type
          value
        }
        photo {
          localFile {
            url
          }
        }
        contact_id
      }
    }
  }
`

const LifeGroupsPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  const lifeGroups = data.lifeGroups.nodes.map(({ photo, ...rest }) => ({
    ...rest,
    photo_url: photo?.localFile?.url,
  }))
  const lifeGroupTags = data.lifeGroupTags.nodes
  const lifeGroupState = useLifeGroupsState(lifeGroups, lifeGroupTags)

  return (
    <>
      <SEO title="Life Groups" />
      <PageCover image={image} title="Life Groups" />
      <QuoteSection />
      <PageContainer>
        <LifeGroupsContext.Provider value={lifeGroupState}>
          <LifeGroupsList />
        </LifeGroupsContext.Provider>
      </PageContainer>
      <GetStartedSection />
    </>
  )
}

export default LifeGroupsPage
