import { createContext, Dispatch, SetStateAction } from 'react'

import { LifeGroup } from '@/models/LifeGroup'

import { LifeGroupFilterOptions, LifeGroupFilters } from './useLifeGroupsState'

type LifeGroupsContextProps = {
  displayedLifeGroups: LifeGroup[]
  filters: LifeGroupFilters
  filterOptions: LifeGroupFilterOptions
  setFilters: Dispatch<SetStateAction<LifeGroupFilters>>
}

export const LifeGroupsContext = createContext<LifeGroupsContextProps>({
  displayedLifeGroups: [],
  filters: {
    lifeStage: '',
    status: '',
    gender: '',
    language: '',
  },
  filterOptions: {
    lifeStage: [],
    status: [],
    gender: [],
    language: [],
  },
  setFilters: () => null,
})
