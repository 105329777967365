import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  title: {
    marginBottom: spacing(6),
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
    },
  },
  description: {
    fontSize: '1.125rem',
    letterSpacing: '0.027777em',
    lineHeight: 1.778,
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  link: {
    color: 'inherit',
  },
}))

export const GetStartedSection: FC = () => {
  const classes = useStyles()

  return (
    <PageContainer background="default">
      <SectionTitle className={classes.title}>Start a Life Group</SectionTitle>
      <p className={classes.description}>
        Starting your own group is easy!
        <br />
        <br />
        We’ll coach you through a simple process and offer you support as well
        as a suggested course of Bible study appropriate to your group.
        <br />
        <br />
        If you any questions, please email us at{' '}
        <a href="mailto:pastor@ibc.berlin" className={classes.link}>
          pastor@ibc.berlin
        </a>
        .
      </p>
    </PageContainer>
  )
}
