import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { Section } from '@/components/common/Section'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.background.default,
    padding: spacing(16, 20, 8),
    [breakpoints.down('sm')]: {
      padding: spacing(12, 16, 6),
    },
    [breakpoints.down('xs')]: {
      padding: spacing(8, 12, 4),
    },
  },
  verse: {
    maxWidth: 774,
    fontSize: '1.75rem',
    lineHeight: 1.643,
    letterSpacing: '0.046786em',
    color: palette.text.primary,
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  reference: {
    display: 'block',
    marginTop: spacing(8),
    fontSize: '1rem',
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
      fontSize: '0.875rem',
    },
  },
}))

export const QuoteSection: FC = () => {
  const classes = useStyles()

  return (
    <Section className={classes.root}>
      <div className={classes.verse}>
        <em>
          We experience authentic, biblical fellowship <strong>not</strong> on
          Sundays when we worship with the ‘crowd’ but when we connect with each
          other in ‘<strong>community</strong>’.
        </em>
        <span className={classes.reference}>Scott Corwin</span>
      </div>
    </Section>
  )
}
