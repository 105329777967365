import {
  Chip,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  CalendarTodayOutlined,
  HighlightOff,
  LocationOnOutlined,
  ScheduleOutlined,
} from '@material-ui/icons'
import { rgba } from 'polished'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import { FormButton } from '@/components/common/FormButton'
import { useFormService } from '@/hooks/useFormService'
import { LifeGroup } from '@/models/LifeGroup'

const ids = {
  form: 'life-group-form',
  name: 'life-group-form-name',
  email: 'life-group-form-email',
  message: 'life-group-form-message',
}

type StyleProps = {
  backgroundUrl: string
  isOpenGroup: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ breakpoints, palette, spacing, typography }) => ({
    paper: {
      borderRadius: 0,
      backgroundColor: palette.background.paper,
      overflowY: 'unset',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      color: '#dddddd',
      transform: 'translate(50%, -50%)',
    },
    dialogContent: {
      padding: spacing(3, 4, 5),
      [breakpoints.down('xs')]: {
        padding: spacing(2, 2, 3),
      },
    },
    image: {
      position: 'relative',
      padding: spacing(18, 4, 4),
      height: 240,
      backgroundImage: ({ backgroundUrl }) => `url(${backgroundUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [breakpoints.down('xs')]: {
        height: 180,
        padding: spacing(10, 4, 4),
      },
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        display: 'block',
        content: '""',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    title: {
      position: 'relative',
      zIndex: 1,
      fontSize: '1.5rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.009em',
      color: palette.primary.contrastText,
      [breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
    infoSection: {
      position: 'relative',
      flex: 1,
      marginBottom: spacing(4),
      [breakpoints.down('sm')]: {},
    },
    chip: {
      position: 'absolute',
      top: 0,
      right: 0,
      borderRadius: 6,
      height: 27,
      fontSize: '1rem',
      backgroundColor: ({ isOpenGroup }) =>
        isOpenGroup ? rgba('#7DAA49', 0.25) : rgba('#E16574', 0.25),
      color: ({ isOpenGroup }) => (isOpenGroup ? '#7DAA49' : '#E16574'),
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    description: {
      margin: spacing(0, 0, 2),
      fontSize: '0.875rem',
      lineHeight: 1.333,
      whiteSpace: 'pre-wrap',
      [breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(3),
      fontSize: '1.25rem',
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('sm')]: {
        marginBottom: spacing(1.5),
        fontSize: '1rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
    },
    icon: {
      marginRight: spacing(2),
      fontSize: '1.2em',
      color: palette.primary.main,
      [breakpoints.down('sm')]: {
        marginRight: spacing(1),
      },
    },
    tags: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: spacing(4),
      [breakpoints.down('xs')]: {
        marginBottom: spacing(2),
      },
      '& > *:not(:last-child)': {
        marginRight: spacing(1.5),
        [breakpoints.down('xs')]: {
          marginRight: spacing(1),
        },
      },
    },
    tag: {
      marginBottom: spacing(1),
    },
    formSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flex: 1,
      [breakpoints.down('sm')]: {},
    },
    fields: {
      alignSelf: 'stretch',
      marginBottom: spacing(2),
    },
    field: {
      marginBottom: spacing(3),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(2),
      },
    },
    button: {
      alignSelf: 'center',
    },
  })
)

type FormInputs = {
  name: string
  email: string
  message: string
}

type LifeGroupFormDialogProps = Omit<DialogProps, 'open'> & {
  index: number | null
  lifeGroup?: LifeGroup
  onClose: () => void
}

export const LifeGroupFormDialog: FC<LifeGroupFormDialogProps> = ({
  index,
  lifeGroup,
  onClose,
  ...rest
}) => {
  const classes = useStyles({
    backgroundUrl: lifeGroup?.photo_url ?? '',
    isOpenGroup: !!lifeGroup?.is_open,
  })
  const { pending, submit } = useFormService()
  const { register, handleSubmit, reset } = useForm<FormInputs>()
  const { ref: nameRef, ...nameRest } = register('name', { required: true })
  const { ref: emailRef, ...emailRest } = register('email', { required: true })
  const { ref: messageRef, ...messageRest } = register('message', {
    required: true,
    minLength: 10,
  })

  const handleClose = () => {
    onClose()
    reset()
  }

  const onSubmit = async (data: FormInputs) => {
    const payload = {
      visitor_name: data.name,
      visitor_email: data.email,
      message: data.message,
      recipient_name: lifeGroup?.name,
      recipient_email: lifeGroup?.contact_id,
      email_type: 'life-group-contact',
    }

    await submit(payload, handleClose)
  }

  if (!lifeGroup) {
    return null
  }

  return (
    <Dialog
      {...rest}
      open={index !== null}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <HighlightOff />
      </IconButton>
      <div className={classes.image}>
        <div className={classes.title}>{lifeGroup.name}</div>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.infoSection}>
          <Chip
            className={classes.chip}
            size="small"
            label={lifeGroup.is_open ? 'Open' : 'Full'}
          />
          <div className={classes.row}>
            <CalendarTodayOutlined className={classes.icon} />
            <span>{lifeGroup.days}</span>
          </div>
          <div className={classes.row}>
            <ScheduleOutlined className={classes.icon} />
            <span>{lifeGroup.time}</span>
          </div>
          <div className={classes.row}>
            <LocationOnOutlined className={classes.icon} />
            <span>{lifeGroup.location}</span>
          </div>
          <div className={classes.tags}>
            {lifeGroup.tags.map((tag) => (
              <Chip
                key={`${tag.type}=${tag.value}`}
                size="small"
                variant="outlined"
                label={tag.value}
                className={classes.tag}
              />
            ))}
          </div>
        </div>
        <form
          id={ids.form}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.formSection}
        >
          <div className={classes.fields}>
            <div className={classes.field}>
              <InputLabel htmlFor={ids.name}>Name</InputLabel>
              <Input
                id={ids.name}
                type="text"
                placeholder="Your name"
                required
                inputRef={nameRef}
                {...nameRest}
              />
            </div>
            <div className={classes.field}>
              <InputLabel htmlFor={ids.email}>Email</InputLabel>
              <Input
                id={ids.email}
                type="email"
                placeholder="Your email"
                required
                inputRef={emailRef}
                {...emailRest}
              />
            </div>
            <div className={classes.field}>
              <InputLabel htmlFor={ids.message}>Message</InputLabel>
              <Input
                id={ids.message}
                placeholder="Your message to the host"
                inputProps={{
                  minLength: 10,
                }}
                rows={3}
                multiline
                required
                inputRef={messageRef}
                {...messageRest}
              />
            </div>
          </div>
          <FormButton pending={pending} className={classes.button}>
            Contact Host
          </FormButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}
