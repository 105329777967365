import {
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, useContext } from 'react'

import { camelToTitleCase } from '@/utils/camelToTitleCase'

import { LifeGroupsContext } from './LifeGroupsContext'
import { orderedTagTypes } from './useLifeGroupsState'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: spacing(8, 0, 10),
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      margin: spacing(4, 0, 5),
    },
  },
  filter: {
    flex: 1,
    '&:not(:last-child)': {
      marginRight: spacing(4),
    },
    [breakpoints.down('sm')]: {
      flex: 'none',
      width: `calc((100% - ${spacing(2)}px) / 2)`,
      '&:not(:last-child)': {
        marginRight: 0,
      },
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export const LifeGroupsFilters: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { filters, filterOptions, setFilters } = useContext(LifeGroupsContext)

  return (
    <div className={classes.root}>
      {orderedTagTypes.map((tagType) => (
        <TextField
          key={tagType}
          select
          variant="outlined"
          margin={isSmallScreen ? 'dense' : 'normal'}
          label={camelToTitleCase(tagType)}
          value={filters[tagType]}
          onChange={(event) =>
            setFilters((prev) => ({
              ...prev,
              [tagType]: event.target.value,
            }))
          }
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            MenuProps: {
              variant: 'menu',
            },
          }}
          className={clsx(classes.filter)}
        >
          {filterOptions[tagType].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ))}
    </div>
  )
}
